import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import { existingCustomer } from "../utils/api-user";
import { useHistory } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import ReactCodeInput from "react-code-input";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { signin } from "../utils/api-auth";
import auth from "../auth/auth-helper";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import GDPRCheckBox from "../gdpr/GDPRCheckBox.jsx";
import { Tooltip } from "@material-ui/core";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 600,
    margin: "auto",
    textAlign: "center",
    marginTop: theme.spacing(15),
    paddingBottom: theme.spacing(2),
  },
  error: {
    verticalAlign: "middle",
  },
  title: {
    marginTop: theme.spacing(2),
    color: theme.palette.openTitle,
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 300,
  },
  submit: {
    margin: "auto",
    marginBottom: theme.spacing(2),
  },
}));

export default function MagicLinkLogin() {
  const initialState = {
    identification: "",
  };

  const [values, setValues] = useState(initialState);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState([{ identification: "", otp_code: "" }]);
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openErrorNoEmail, setOpenErrorNoEmail] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openCustomerInfo] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");
  const [message, setMessage] = useState("");

  // GDPR
  const [gdprChecked, setGDPRChecked] = useState(false);

  const history = useHistory();
  const goHome = () => {
    history.push("/");
  };

  const goNewCustomer = () => {
    history.push("/signup");
  };

  // const goNoEmailInput = () => {
  //   history.push("/noemail");
  // };

  const handleChange = (name) => (event) => {
    setValues({
      ...values,
      [name]: event.target.value,
    });
  };

  // const handleClick = () => {
  //   setOpen(true);
  // };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setOpenError(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setOpenError(false);
    goNewCustomer();
  };

  // const handleCloseNoEmail = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setOpen(false);
  //   setOpenErrorNoEmail(false);
  //   goNoEmailInput();
  // };

  const handleCloseConfirmation = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenConfirmation(false);
  };

  const handleVerify = () => {
    const user = {
      identification: values.identification.match(/^[^_]*/g) || values.identification || undefined,
      otp_code: verifyCode || undefined,
      name: data[0].name || undefined,
    };
    signin(user).then((data) => {
      if (data.success) {
        setErrors({ otp_code: "", identification: "" });
        auth.authenticate(data, () => {
          setValues({ redirectToReferrer: true });
        });
      } else {
        if (data.error) {
          setErrors({ otp_code: data.error, identification: "" });
        } else {
          setErrors({ otp_code: data.otp_code, identification: "" });
        }
      }
    });
  };

  const clickSubmit = () => {
    const customer = {
      identification: values.identification.trim().toLowerCase() || undefined,
    };
    existingCustomer(customer).then((data) => {
      if (data.success) {
        setErrors({ identification: "", otp_code: "" });
        setData(data.data);
        setOpen(true);
        setOpenConfirmation(true);
      } else if (data.identification) {
        setErrors({ identification: data.identification, otp_code: "" });
      } else {
        setMessage(data.message);
        setErrors({ identification: data.identification, otp_code: "" });
        setOpenErrorNoEmail(true);
      }
    });
  };

  const classes = useStyles();
  let lastPath = sessionStorage.getItem("lastPath");

  const { from } = window.location.state || {
    from: {
      pathname: lastPath,
    },
  };
  if (values.redirectToReferrer) {
    return <Redirect to={from} />;
  }

  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Typography
            type="headline"
            variant="h4"
            gutterBottom
            align="center"
            className={classes.title}
          >
            ACsenteret Support Portal{" "}
          </Typography>
          <p>Legg inn ditt mobilnummer eller e-post for å gå videre.</p>
          <InputLabel htmlFor="identification"></InputLabel>
          <TextField
            id="identification"
            type="text"
            label="Mobilnummer eller epost"
            className={classes.textField}
            value={values.identification}
            inputProps={{ min: 0, style: { textAlign: "center" } }}
            onChange={handleChange("identification")}
            margin="normal"
            variant="outlined"
            helperText={errors ? errors.identification : ""}
            error={errors.identification ? true : false}
          />
          <GDPRCheckBox checked={gdprChecked} onChecked={setGDPRChecked} />
          <br />
          <Tooltip title={!gdprChecked ? 'Du må godta lagring av data før du kan fortsette' : ''} arrow>
            <span>
              <Button
                size="large"
                color="primary"
                variant="contained"
                onClick={clickSubmit}
                className={classes.submit}
                disabled={!gdprChecked}
              >
                Hent bekreftelsekode
              </Button>
            </span>
          </Tooltip>
          <br />
          <Link to="/signup" className={classes.submit}>
            <Tooltip title={!gdprChecked ? 'Du må godta lagring av data før du kan fortsette' : ''} arrow>
              <span>
                <Button
                  size="large"
                  color="default"
                  variant="contained"
                  onClick={clickSubmit}
                  className={classes.submit}
                  disabled={!gdprChecked}
                >
                  Oppdrett ny kunde
                </Button>
              </span>
            </Tooltip>
          </Link>
          <InputLabel htmlFor="identification">
            <Snackbar
              open={open}
              autoHideDuration={3000}
              onClose={handleCloseSuccess}
            >
              <Alert onClose={handleCloseSuccess} severity="success">
                Vi fant deg i våre systemer, en E-post eller SMS med
                bekreftelsekode er sendt (avhengig om du la inn mobilnummer
                eller epost i identifikasjons feltet).
                <p>Legg inn koden vi har sendt deg for å gå videre.</p>
              </Alert>
            </Snackbar>
            <Snackbar
              open={openError}
              autoHideDuration={3000}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="error">
                <p>{message}</p>
              </Alert>
            </Snackbar>
            <Snackbar
              open={openErrorNoEmail}
              autoHideDuration={3000}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="error">
                <p>{message}</p>
              </Alert>
            </Snackbar>
            <br />
            <br />
          </InputLabel>
        </CardContent>
        <CardActions>
          <Button
            color="default"
            variant="contained"
            onClick={goHome}
            className={classes.submit}
          >
            Til fremsiden
          </Button>
        </CardActions>
      </Card>
      <Dialog open={openConfirmation} disableBackdropClick maxWidth="xs">
        <DialogTitle align="center">Bekreftelsekode</DialogTitle>
        <DialogContent>
          <DialogContentText align="center">
            Legg inn bekreftelsekoden fra SMS eller E-post her
          </DialogContentText>
          <DialogContentText align="center">
            <ReactCodeInput
              id="otp_code"
              name="otp_code"
              value={values.otp_code}
              onChange={(value) => setVerifyCode(value)}
              type="number"
              fields={5}
            />
          </DialogContentText>
          <InputLabel defaultValue="Error" error>
            {errors.otp_code}
          </InputLabel>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            //onClick={() => clickSubmit()}
            onClick={handleVerify}
            className={classes.submit}
          >
            Bekreft
          </Button>
          <Button
            color="secondary"
            variant="contained"
            //onClick={() => clickSubmit()}
            onClick={handleCloseConfirmation}
            className={classes.submit}
          >
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openCustomerInfo} disableBackdropClick maxWidth="xs">
        <DialogTitle align="center">Kontakt info</DialogTitle>
        <DialogContent>
          <DialogContentText align="center">
            Bekreft at følgende kontakt informasjon er riktig.
          </DialogContentText>
          <DialogContentText align="center"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            //onClick={() => clickSubmit()}
            onClick={handleVerify}
            className={classes.submit}
          >
            Bekreft
          </Button>
          <Button
            color="secondary"
            variant="contained"
            //onClick={() => clickSubmit()}
            onClick={handleCloseConfirmation}
            className={classes.submit}
          >
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
