//basic grid layout
import React from "react";
import useReactRouter from "use-react-router";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import BuildRoundedIcon from "@material-ui/icons/BuildRounded";
import RoomServiceRoundedIcon from "@material-ui/icons/RoomServiceRounded";
import FindInPageRoundedIcon from "@material-ui/icons/FindInPageRounded";
import ReceiptRoundedIcon from "@material-ui/icons/ReceiptRounded";
import VpnKeyRoundedIcon from "@material-ui/icons/VpnKeyRounded";
import { Link } from "react-router-dom";
import PersonAddTwoToneIcon from "@material-ui/icons/PersonAddTwoTone";
import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import auth from "./auth/auth-helper";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2), //grid padding
    textAlign: "center",
    color: theme.palette.text.primary,
    height: "220px",
    width: "270px",
    paddingTop: "1.5em",
    "&:hover": {
      boxShadow:
        "0 0.9px 2.5px -8px rgba(0, 0, 0, 0.02),0 2.1px 6.1px -8px rgba(0, 0, 0, 0.028), 0 3.9px 11.5px -8px rgba(0, 0, 0, 0.035), 0 6.9px 20.5px -8px rgba(0, 0, 0, 0.042), 0 13px 38.4px -8px rgba(0, 0, 0, 0.05), 0 31px 92px -8px rgba(0, 0, 0, 0.07)",
    },
    transition: "box-shadow .5s",
  },
  paperDisabled: {
    padding: theme.spacing(2), //grid padding
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "220px",
    width: "270px",
    paddingTop: "1.5em",
  },
}));

function FormRow() {
  const classes = useStyles();
  const { history } = useReactRouter();

  return (
    //return renders the grid
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={4}>
          <Link to="/bestill-tjeneste">
            <Button className={classes.button}>
              <Paper className={classes.paper}>
                <BuildRoundedIcon style={{ fontSize: "4rem" }} />
                <Typography component={"div"} variant="h5" gutterBottom>
                  Bestill tjeneste
                </Typography>
                <strong>
                  <small>
                    <br />
                    Montering, demontering, flytting, reparasjon av varmepumpe
                  </small>
                </strong>
              </Paper>
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Link to="/bestill-service">
            <Button className={classes.button}>
              <Paper className={classes.paper}>
                <RoomServiceRoundedIcon style={{ fontSize: "4rem" }} />
                <Typography component={"div"} variant="h5" gutterBottom>
                  Vedlikeholds
                  <br />
                  service
                </Typography>
                <strong>
                  <small>
                    Jevnlig vedlikeholdsservice på din varmepumpe sikrer lang
                    levetid
                  </small>
                </strong>
              </Paper>
            </Button>
          </Link>
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Link to="/bestill-deler">
            <Button className={classes.button}>
              <Paper className={classes.paper}>
                <AssignmentTurnedInIcon style={{ fontSize: "4rem" }} />
                <Typography component={"div"} variant="h5" gutterBottom>
                  Bestill deler
                </Typography>
                <strong>
                  <p>
                    <br />
                    <small>Bestill deler til din varmepumpe.</small>
                  </p>
                </strong>
              </Paper>
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} style={{ display: "none" }}>
          {auth.isAuthenticated() ? (
            <Button
              disabled
              target="_blank"
              href="https://kontor.acsenteret.no:442/Bruksanvisninger/"
            >
              <Paper className={classes.paperDisabled}>
                <FindInPageRoundedIcon style={{ fontSize: "4rem" }} />
                <Typography component={"div"} variant="h5" gutterBottom>
                  Manualer og bruksanvisninger
                </Typography>
                <strong>
                  <small>
                    Finn manual eller bruksanvisning for din varmepumpe.
                  </small>
                </strong>
              </Paper>
            </Button>
          ) : (
            <Button
              disabled
              style={{ display: "none" }}
              className={classes.button}
            >
              <Paper className={classes.paperDisabled}>
                <FindInPageRoundedIcon style={{ fontSize: "4rem" }} />
                <Typography component={"div"} variant="h5" gutterBottom>
                  Manualer og bruksanvisninger
                </Typography>
                <strong>
                  <small>
                    Finn manual eller bruksanvisning for din varmepumpe.
                  </small>
                </strong>
              </Paper>
            </Button>
          )}
        </Grid>
        {auth.isAuthenticated() ? (
          <Grid item xs={12} sm={6} lg={4}>
            <Link to="/feilsøking-ll">
              <Button className={classes.button}>
                <Paper className={classes.paper}>
                  <ContactSupportIcon style={{ fontSize: "4rem" }} />
                  <Typography component={"div"} variant="h5" gutterBottom>
                    Luft/Luft Varmepumpe
                  </Typography>
                  <strong>
                    <small>Reklamasjon og feilsøking.</small>
                  </strong>
                </Paper>
              </Button>
            </Link>
          </Grid>
        ) : (
          <Grid item xs={12} sm={6} lg={4}>
            <Button disabled className={classes.button}>
              <Paper className={classes.paperDisabled}>
                <ContactSupportIcon style={{ fontSize: "4rem" }} />
                <Typography component={"div"} variant="h5" gutterBottom>
                  Luft/Luft Varmepumpe
                </Typography>
                <strong>
                  <small>Reklamasjon og feilsøking.</small>
                  <br />
                  <small style={{ textDecoration: "underline" }}>
                    Innlogging kreves.
                  </small>
                </strong>
              </Paper>
            </Button>
          </Grid>
        )}
        {auth.isAuthenticated() ? (
          <Grid item xs={12} sm={6} lg={4}>
            <Link to="/feilsøking-lv">
              <Button className={classes.button}>
                <Paper className={classes.paper}>
                  <ContactSupportIcon style={{ fontSize: "4rem" }} />
                  <Typography component={"div"} variant="h5" gutterBottom>
                    Luft/Vann Varmepumpe
                  </Typography>
                  <strong>
                    <small>Reklamasjon og feilsøking.</small>
                  </strong>
                </Paper>
              </Button>
            </Link>
          </Grid>
        ) : (
          <Grid item xs={12} sm={6} lg={4}>
            <Button disabled className={classes.button}>
              <Paper className={classes.paperDisabled}>
                <ContactSupportIcon style={{ fontSize: "4rem" }} />
                <Typography component={"div"} variant="h5" gutterBottom>
                  Luft/Vann Varmepumpe
                </Typography>
                <strong>
                  <small>Reklamasjon og feilsøking.</small>
                  <br />
                  <small style={{ textDecoration: "underline" }}>
                    Innlogging kreves.
                  </small>
                </strong>
              </Paper>
            </Button>
          </Grid>
        )}
        {auth.isAuthenticated() ? (
          <Grid item xs={12} sm={6} lg={4}>
            <Link to="/feilsøking-sol">
              <Button className={classes.button}>
                <Paper className={classes.paper}>
                  <ContactSupportIcon style={{ fontSize: "4rem" }} />
                  <Typography component={"div"} variant="h5" gutterBottom>
                    Solskjerming
                  </Typography>
                  <strong>
                    <br />
                    <small>Reklamasjon og feilsøking.</small>
                  </strong>
                </Paper>
              </Button>
            </Link>
          </Grid>
        ) : (
          <Grid item xs={12} sm={6} lg={4}>
            <Button disabled className={classes.button}>
              <Paper className={classes.paperDisabled}>
                <ContactSupportIcon style={{ fontSize: "4rem" }} />
                <Typography component={"div"} variant="h5" gutterBottom>
                  Solskjerming
                </Typography>
                <strong>
                  <small>Reklamasjon og feilsøking.</small>
                  <br />
                  <br />
                  <small style={{ textDecoration: "underline" }}>
                    Innlogging kreves.
                  </small>
                </strong>
              </Paper>
            </Button>
          </Grid>
        )}
        <Grid item xs={12} sm={6} lg={4}>
          <Link to="/tilbakemelding">
            <Button className={classes.button}>
              <Paper className={classes.paper}>
                <RoomServiceRoundedIcon style={{ fontSize: "4rem" }} />
                <Typography component={"div"} variant="h5" gutterBottom>
                  Tilbakemelding
                </Typography>
                <strong>
                  <small>
                    Ris, Ros, Klage, Avviksrapportering knyttet til vår utførelse av oppdraget
                  </small>
                </strong>
              </Paper>
            </Button>
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          lg={4}
          container
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Paper className={classes.paper}>
            <VpnKeyRoundedIcon style={{ fontSize: "4rem" }} />
            <Typography component={"div"} variant="h5" gutterBottom>
              {auth.isAuthenticated() ? "Logg ut" : "Logg på"}
            </Typography>
            {auth.isAuthenticated() ? (
              <Button
                disabled
                style={{ display: auth.isAuthenticated() ? "none" : "inherit" }}
                variant="contained"
                size="small"
                color="default"
                startIcon={<SearchTwoToneIcon fontSize="small" />}
              >
                Eksisterende kunde
              </Button>
            ) : (
              <p>
                <Link to="/signup">
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    style={{ width: "100%" }}
                    startIcon={<PersonAddTwoToneIcon fontSize="small" />}
                  >
                    Ny kunde
                  </Button>
                </Link>
              </p>
            )}
            {auth.isAuthenticated() ? (
              <Button
                variant="contained"
                size="large"
                color="secondary"
                style={{ width: "100%" }}
                onClick={() => {
                  auth.signout(() => history.push("/"));
                }}
                startIcon={<ExitToAppIcon fontSize="small" />}
              >
                Logg ut.
              </Button>
            ) : (
              <Link to="/existing">
                <Button
                  variant="contained"
                  size="large"
                  style={{ width: "100%" }}
                  color="default"
                  startIcon={<SearchTwoToneIcon fontSize="small" />}
                >
                  Allerede kunde
                </Button>
              </Link>
            )}
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default FormRow;
