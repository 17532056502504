const options = [
  {
    id: 1,
    value: "vp-temp-reg",
    label: "Temperatur regulering",
    links: [
      {
        isChecked: false,
        desc: "Generelt",
        url: "https://docs.google.com/document/d/13wTi6QxGlzSy2D-qZ867Szu4f0eyp_5CDe0vI_b-wr8/edit#heading=h.pydfzcbjl8ft",
      },
      {
        isChecked: false,
        desc: "Temperatur",
        url: "https://docs.google.com/document/d/13wTi6QxGlzSy2D-qZ867Szu4f0eyp_5CDe0vI_b-wr8/edit#heading=h.13fpxex0y3vo",
      },
    ],
  },
  {
    id: 2,
    value: "no-noise",
    label: "Støy / Lyder",
    links: [
      {
        isChecked: false,
        desc: "Normal lyd fra utedel",
        url: "https://docs.google.com/document/d/13wTi6QxGlzSy2D-qZ867Szu4f0eyp_5CDe0vI_b-wr8/edit#heading=h.pgr7vs81t173",
      },
      {
        isChecked: false,
        desc: "Unormal lyd",
        url: "https://docs.google.com/document/d/13wTi6QxGlzSy2D-qZ867Szu4f0eyp_5CDe0vI_b-wr8/edit#heading=h.nezwyoa9r7fi",
      },
    ],
  },
  {
    id: 3,
    value: "temp-water-is-rim",
    label: "Vann / is / rim / damp / kondens fra utedel",
    links: [
      {
        isChecked: false,
        desc: "Varmedrift",
        url: "https://docs.google.com/document/d/13wTi6QxGlzSy2D-qZ867Szu4f0eyp_5CDe0vI_b-wr8/edit#heading=h.sqiz9uf2s5if",
      },
    ],
  },
  {
    id: 4,
    value: "vp-flashing-light-msg",
    label: "Blinkende lys / feilmeldinger",
    links: [
      {
        isChecked: false,
        desc: "Blinkende lys / feilmeldinger",
        url: "https://docs.google.com/document/d/13wTi6QxGlzSy2D-qZ867Szu4f0eyp_5CDe0vI_b-wr8/edit#heading=h.bo2yzvm4eeiu",
      },
    ],
  },
  {
    id: 5,
    value: "pump-no-start",
    label: "Pumpen vil ikke gå",
    links: [
      {
        isChecked: false,
        desc: "Generelt",
        url: "https://docs.google.com/document/d/13wTi6QxGlzSy2D-qZ867Szu4f0eyp_5CDe0vI_b-wr8/edit#heading=h.arwxoyz9vhau",
      },
    ],
  },
  {
    id: 6,
    value: "winter-problems",
    label: "Vanlige problem om vinteren",
    links: [
      {
        isChecked: false,
        desc: "Dårlig varmekapasitet",
        url: "https://docs.google.com/document/d/13wTi6QxGlzSy2D-qZ867Szu4f0eyp_5CDe0vI_b-wr8/edit#",
      },
    ],
  },
  {
    id: 7,
    value: "wifi",
    label: "WiFi Tilkobling",
    links: [
      {
        isChecked: false,
        desc: "Problem med wifi tilkobling",
        url: "https://docs.google.com/document/d/13wTi6QxGlzSy2D-qZ867Szu4f0eyp_5CDe0vI_b-wr8/edit#heading=h.3y1n2o757fht",
      },
    ],
  },
  {
    id: 8,
    value: "kosmetisk",
    label: "Kosmetisk reklamasjon",
    links: [
      {
        isChecked: false,
        desc: "Kosmetisk reklamasjon.",
        url: "https://docs.google.com/document/d/13wTi6QxGlzSy2D-qZ867Szu4f0eyp_5CDe0vI_b-wr8/edit#heading=h.zej5ejmihm95",
      },
    ],
  },
];

module.exports = options;
