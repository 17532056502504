
export default function CookieConsent() {
  return (
      <>
      <h1> Retningslinjer for informasjonskapsler </h1>
        Denne informasjonskapselpolicyen (& quot; Policy & quot;) beskriver hva
        cookies er og hvordan og de blir brukt av
        <a target="_ blank" rel="nofollow" href="http://support.acsenteret.no">

          support.acsenteret.no
        </a>
        nettsted (& quot; Nettsted & quot; eller & quot; Service & quot;) og
        noen av dets relaterte produkter og tjenester (samlet, & quot; Tjenester
        & quot;). Denne policyen er en juridisk bindende avtale mellom deg
        ("bruker", "deg" eller "din") og denne nettstedsoperatøren ("operatør",
        "vi", "oss" eller "vår") . Du bør lese denne policyen slik at du kan
        forstå hvilke typer informasjonskapsler vi bruker, informasjonen vi
        samler inn ved hjelp av informasjonskapsler og hvordan informasjonen
        brukes.
        <h2> Hva er informasjonskapsler? </h2>
        <p>
          Cookies er små biter av data lagret i tekstfiler som lagres på
          datamaskinen din eller andre enheter når nettsteder lastes inn i en
          nettleser. De brukes mye for å huske deg og dine preferanser, enten
          for et enkelt besøk (gjennom en "økt-informasjonskapsel") eller for
          flere gjentatte besøk (ved hjelp av en "vedvarende
          informasjonskapsel").
        </p>
        <p>

          Sesjonskapsler er midlertidige informasjonskapsler som brukes i løpet
          av besøket ditt på nettstedet, og de utløper når du lukker
          nettleseren.
        </p>
        <p>

          Vedvarende informasjonskapsler brukes til å huske dine preferanser på
          nettstedet vårt og forbli på din stasjonære eller mobile enhet, selv
          etter at du har lukket nettleseren eller startet datamaskinen på nytt.
          De sørger for en jevn og effektiv opplevelse for deg når du besøker
          nettstedet og tjenestene.
        </p>
        <p>

          Informasjonskapsler kan settes av nettstedet (& quot; førsteparts
          informasjonskapsler & quot;), eller av tredjeparter, for eksempel de
          som betjener innhold eller tilbyr reklame- eller analysetjenester på
          nettstedet (& quot; tredjeparts informasjonskapsler & quot;). Disse
          tredjepartene kan gjenkjenne deg når du besøker nettstedet vårt og
          også når du besøker visse andre nettsteder. Du kan lære mer om
          informasjonskapsler og hvordan de fungerer i denne
          <a
            target="_blank"
            href="https://www.websitepolicies.com/blog/cookies"
          >

            guiden
          </a>
        </p>
        <h2> Hvilken type informasjonskapsler bruker vi? </h2>
        <h3> Nødvendige informasjonskapsler </h3>
        <p>

          Nødvendige informasjonskapsler lar oss tilby deg den best mulige
          opplevelsen når du åpner og navigerer gjennom nettstedet vårt og
          bruker funksjonene. Disse informasjonskapslene lar oss for eksempel
          gjenkjenne at du har opprettet en konto og har logget på den kontoen
          for å få tilgang til innholdet.
        </p>
        <h3> Funksjonscookies </h3>
        <p>
          Funksjonskapsler lar oss betjene nettstedet og tjenestene i samsvar
          med valgene du tar. For eksempel vil vi gjenkjenne brukernavnet ditt
          og huske hvordan du tilpasset nettstedet og tjenestene under
          fremtidige besøk.
        </p>
        <h2> Hva er informasjonskapselalternativene dine? </h2>
        <p>

          Hvis du ikke liker ideen om informasjonskapsler eller visse typer
          informasjonskapsler, kan du endre nettleserens innstillinger for å
          slette informasjonskapsler som allerede er angitt, og for å ikke godta
          nye informasjonskapsler. Gå til
          <a target="_blank" href="https://www.internetcookies.org">

            internetcookies.org
          </a>
          for å lære mer om hvordan du gjør dette.
        </p>
        <p>
          Vær imidlertid oppmerksom på at hvis du sletter informasjonskapsler
          eller ikke godtar dem, vil du kanskje ikke kunne bruke alle
          funksjonene nettstedet og tjenestene tilbyr.
        </p>
        <h2> Endringer og endringer </h2>
        <p>

          Vi forbeholder oss retten til når som helst å endre denne policyen
          eller dens vilkår knyttet til nettstedet og tjenestene, med virkning
          fra publisering av en oppdatert versjon av denne policyen på
          nettstedet. Når vi gjør det, vil vi legge ut et varsel på hovedsiden
          til nettstedet. Fortsatt bruk av nettstedet og tjenestene etter slike
          endringer skal utgjøre ditt samtykke til slike endringer.
        </p>
        <h2> Aksept av denne policyen </h2>
        <p>

          Du erkjenner at du har lest denne policyen og godtar alle vilkårene.
          Ved å få tilgang til og bruke nettstedet og tjenestene, godtar du å
          være bundet av denne policyen. Hvis du ikke godtar å følge vilkårene i
          denne policyen, er du ikke autorisert til å få tilgang til eller bruke
          nettstedet og tjenestene.
      </p>
      <h2> Kontakt oss </h2>
      <p>

        Hvis du vil kontakte oss for å forstå mer om disse retningslinjene eller
        ønsker å kontakte oss angående spørsmål knyttet til bruken av
        informasjonskapsler, kan du sende en e-post til & # 115; opp & # 112; o
        & # 114; t & # 39; # 64; et & # 99; se & # 110; & # 116; er & # 101; t &
        # 46; nr.
      </p>
      <p> Dette dokumentet ble sist oppdatert 22. mars 2021 </p>
    </>
  );
}
