import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography, makeStyles } from '@material-ui/core'
import React from 'react'
import Cookies from 'universal-cookie';
import { getSessionStorageItem, setSessionStorageItem } from '../../storage/sessionStorage';
import auth from '../auth/auth-helper';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'underline',
  }
}));

const checkForCookie = (key) => {
  const cookies = new Cookies();
  return cookies.get(key);
}

const GDPRDialog = () => {
  const acceptedGDPR = getSessionStorageItem('acceptedGDPR');
  const isAuthenticated = auth.isAuthenticated();
  const [open, setOpen] = React.useState(!(isAuthenticated || (!!acceptedGDPR ?? false)));
  const classes = useStyles();

  const onAccept = () => {
    // If this cookie exist => set the session storage item, else skip this
    const cookie = checkForCookie('wpcc');
    if (!!cookie) {
      setSessionStorageItem('acceptedGDPR', true);
    }
    setOpen(false);
  }

  return (
    <Dialog open={open}>
      <DialogTitle>
        Personvern og informasjonssikkerhetsavtale (GDPR)
      </DialogTitle>
      <DialogContent>
        <Typography>
          Vi er forpliktet til å beskytte og respektere&nbsp;
          <Typography className={classes.link} component={Link} href="https://acsenteret.no/personvern/" target="_blank" rel="noopener noreferrer">personvernet</Typography>
          &nbsp;ditt, og vi bruker bare din personlige informasjon til å levere produktene og tjenestene du ba om fra oss
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onAccept} variant="contained" color="primary">
          Godta
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default GDPRDialog