const options = [
  {
    id: 1,
    value: "vp-no-start",
    label: "Varmepumpen starter ikke",
    links: [
      {
        id: 10,
        isChecked: false,
        desc: "Pumpen vil ikke gå",
        url: "https://docs.google.com/document/d/1y90C5Mv6eJSof-lryOT2oJRMcfPBXoXUX1bcGmO_cY0/edit#bookmark=id.vg6fdmb2czan",
      },
    ],
  },
  {
    id: 2,
    value: "no-low-temp",
    label: "Ingen eller lav varme",
    links: [
      {
        id: 20,
        isChecked: false,
        desc: "Viftehastighet/temperatur regulering",
        url: "https://docs.google.com/document/d/1y90C5Mv6eJSof-lryOT2oJRMcfPBXoXUX1bcGmO_cY0/edit#bookmark=id.hklrfhhgo9m7",
      },
      {
        isChecked: false,
        desc: "Dårlig varmekapasitet",
        url: "https://docs.google.com/document/d/1y90C5Mv6eJSof-lryOT2oJRMcfPBXoXUX1bcGmO_cY0/edit#bookmark=id.9lnmlxn5pkow",
      },
      {
        id: 21,
        isChecked: false,
        desc: "Pumpen vil ikke gå",
        url: "https://docs.google.com/document/d/1y90C5Mv6eJSof-lryOT2oJRMcfPBXoXUX1bcGmO_cY0/edit#bookmark=id.vg6fdmb2czan",
      },
      {
        id: 22,
        isChecked: false,
        desc: "Vann/is/damp fra utedel",
        url: "https://docs.google.com/document/d/1y90C5Mv6eJSof-lryOT2oJRMcfPBXoXUX1bcGmO_cY0/edit#bookmark=id.3c7e5y5utmwr",
      },
    ],
  },
  {
    id: 3,
    value: "temp-high-low",
    label: "Romtemperatur for høy/lav i forhold til innstilt temperatur",
    links: [
      {
        id: 30,
        isChecked: false,
        desc: "Viftehastighet/temperatur regulering",
        url: "https://docs.google.com/document/d/1y90C5Mv6eJSof-lryOT2oJRMcfPBXoXUX1bcGmO_cY0/edit#bookmark=id.hklrfhhgo9m7",
      },
    ],
  },
  {
    id: 4,
    value: "vp-no-stop",
    label: "Varmepumpen stopper ikke",
    links: [
      {
        id: 40,
        isChecked: false,
        desc: "Viftehastighet/temperatur regulering",
        url: "https://docs.google.com/document/d/1y90C5Mv6eJSof-lryOT2oJRMcfPBXoXUX1bcGmO_cY0/edit#bookmark=id.hklrfhhgo9m7",
      },
    ],
  },
  {
    id: 5,
    value: "periodic-stop-start",
    label: "Stopper periodisk og starter igjen etter liten en stund",
    links: [
      {
        id: 50,
        isChecked: false,
        desc: "Vann/is/damp fra utedel",
        url: "https://docs.google.com/document/d/1y90C5Mv6eJSof-lryOT2oJRMcfPBXoXUX1bcGmO_cY0/edit#bookmark=id.3c7e5y5utmwr",
      },
    ],
  },
  {
    id: 6,
    value: "steam-water",
    label: "Utedelen er dekket med rim/is/snø",
    links: [
      {
        id: 60,
        isChecked: false,
        desc: "Vann/is/damp fra utedel",
        url: "https://docs.google.com/document/d/1y90C5Mv6eJSof-lryOT2oJRMcfPBXoXUX1bcGmO_cY0/edit#bookmark=id.3c7e5y5utmwr",
      },
    ],
  },
  {
    id: 7,
    value: "water-ice-out",
    label: "Damp (kan se ut som røyk) eller vann fra utedel",
    links: [
      {
        id: 70,
        isChecked: false,
        desc: "Vann/is/damp fra utedel",
        url: "https://docs.google.com/document/d/1y90C5Mv6eJSof-lryOT2oJRMcfPBXoXUX1bcGmO_cY0/edit#bookmark=id.3c7e5y5utmwr",
      },
    ],
  },
  {
    id: 8,
    value: "water-ice-inside",
    label: "Damp (kan se ut som røyk) eller vann fra innedel",
    links: [
      {
        id: 80,
        isChecked: false,
        desc: "Vann/is/damp fra innedel",
        url: "https://docs.google.com/document/d/1y90C5Mv6eJSof-lryOT2oJRMcfPBXoXUX1bcGmO_cY0/edit#bookmark=id.dug8ffowumbu",
      },
    ],
  },
  {
    id: 9,
    value: "noise-out-in",
    label: "Støy fra inne eller utedel",
    links: [
      {
        id: 90,
        isChecked: false,
        desc: "Støy/Lyd inne eller utedel",
        url: "https://docs.google.com/document/d/1y90C5Mv6eJSof-lryOT2oJRMcfPBXoXUX1bcGmO_cY0/edit#bookmark=id.eqci7y51fema",
      },
    ],
  },
  {
    id: 10,
    value: "flashing-light",
    label: "Blinkende lys eller melding på innedel",
    links: [
      {
        id: 100,
        isChecked: false,
        desc: "Blinkende lys/feilmeldinger",
        url: "https://docs.google.com/document/d/1y90C5Mv6eJSof-lryOT2oJRMcfPBXoXUX1bcGmO_cY0/edit#bookmark=id.5sta1vwlf928",
      },
    ],
  },
  {
    id: 11,
    value: "wifi-conn",
    label: "Problem med WiFi tilkobling",
    links: [
      {
        id: 110,
        isChecked: false,
        desc: "WiFi Tilkobling",
        url: "https://docs.google.com/document/d/1y90C5Mv6eJSof-lryOT2oJRMcfPBXoXUX1bcGmO_cY0/edit#bookmark=id.9nu298lyjael",
      },
    ],
  },
  {
    id: 12,
    value: "kosmetisk",
    label: "Kosmetisk reklamasjon",
    links: [
      {
        id: 120,
        isChecked: false,
        desc: "Kosmetisk reklamasjon.",
        url: "https://docs.google.com/document/d/1y90C5Mv6eJSof-lryOT2oJRMcfPBXoXUX1bcGmO_cY0/edit#heading=h.zej5ejmihm95",
      },
    ],
  },
  {
    id: 13,
    value: "vond-lukt",
    label: "Vond lukt",
    links: [
      {
        id: 130,
        isChecked: false,
        desc: "Les mer om hva som kan gi vond lukt fra varmepumpen.",
        url: "https://docs.google.com/document/d/1y90C5Mv6eJSof-lryOT2oJRMcfPBXoXUX1bcGmO_cY0/edit#bookmark=id.slnlkzcy367x",
      },
    ],
  },
];

module.exports = options;
