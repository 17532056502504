// api-user.js
import axios from "axios";

export const registerUser = async (user) => {
  console.log("Inside new custom api-user.js");
  try {
    const response = await fetch("/api/new_customer/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });
    return response.json();
  } catch (err) {
    return console.log(err);
  }
};

export const existingCustomer = async (customer) => {
  try {
    //if (customer.identification.match(/(?<=_)([a-zA-Z-]+@acsenteret.no)(?=_)/g)) {
    //  customer.identification=customer.identification.match(/^[^_]*/g);
    // }
    //customer.identification=customer.identification.match(/^[^_]*/g);
    const response = await fetch("/api/existing_customer/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(customer),
    });
    return response.json();
  } catch (err) {
    return console.log(err);
  }
};

export const updateCustomerEmail = async (user) => {
  try {
    const response = await fetch("/api/update_email/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });
    return response.json();
  } catch (err) {
    return console.log(err);
  }
};

export const createTicket = async (ticket_data) => {
  try {
    const response = await fetch("/api/opprett-sak/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(ticket_data),
    });
    return response.json();
  } catch (err) {
    return console.log(err);
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await fetch("/accounts/glemt-passord/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(email),
    });
    return response.json();
  } catch (err) {
    return console.log(err);
  }
};

export const changePassword = async (token, password, passwordConfirm) => {
  try {
    const response = await fetch("/accounts/tilbakestill-passord/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(token, password, passwordConfirm),
    });
    return response.json();
  } catch (err) {
    return console.log(err);
  }
};

export const findUserProfile = async (params, credentials) => {
  try {
    const response = await fetch("/api/users/" + params.userId, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + credentials.t,
      },
    });
    return response.json();
  } catch (err) {
    return console.error(err);
  }
};

export const deleteUser = async (params) => {
  try {
    await axios.delete("/api/removeUser", {
      data: {
        _id: params.userId,
      },
    });
  } catch (err) {
    return console.log("Something went wrong: ", err);
  }
};

export const magicSignin = async (token) => {
  console.log("Inside api-auth/magicsignin : " + token);
  try {
    const response = await axios
      .post("/api/magic-signin/", {
        user: {
          success: true,
          token: token,
        },
      })
    return response;
  } catch (error) {
    return console.error("Something went wrong: ", error);
  }
};

//export const magicSignin = async (token) => {
//  try {
//    console.log(">> Inside magicSignin <<");
//
//    const response = await fetch("/api/magic-signin/", {
//      method: "POST",
//      credentials: "include",
//      headers: {
//        Accept: "application/json",
//        "Content-Type": "application/json",
//      },
//      body: JSON.stringify(token),
//    });
//
//    return response.json();
//  } catch (err) {
//    return console.log(err);
//  }
//};
