import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import { updateCustomerEmail } from "../utils/api-user";
import { useHistory } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import MuiAlert from "@material-ui/lab/Alert";
import auth from "../auth/auth-helper";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 600,
    margin: "auto",
    textAlign: "center",
    marginTop: theme.spacing(15),
    paddingBottom: theme.spacing(2),
  },
  error: {
    verticalAlign: "middle",
  },
  title: {
    marginTop: theme.spacing(2),
    color: theme.palette.openTitle,
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 300,
  },
  submit: {
    margin: "auto",
    marginBottom: theme.spacing(2),
  },
}));

export default function UpdateCustomerEmail() {
  const initialState = {
    identification: "",
  };

  const [values, setValues] = useState(initialState);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState([{ identification: "", otp_code: "" }]);
  const [openErrorNoEmail, setOpenErrorNoEmail] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [message, setMessage] = useState("");

  const history = useHistory();
  const goHome = () => {
    auth.signout(() => history.push("/existing"));
  };

  const goFrontpage = () => {
    history.push("/");
  };

  const handleChange = (name) => (event) => {
    setValues({
      ...values,
      [name]: event.target.value,
    });
  };

  const handleCloseEmailError = () => {
    setOpenErrorNoEmail(false);
  };

  const clickSubmit = () => {
    const customer = {
      identification: values.email || undefined,
      identification_confirm: values.email_confirm || undefined,
      customer_id: auth.isAuthenticated().data.otp_id || undefined,
      current_email: auth.isAuthenticated().data.email || undefined,
    };

    updateCustomerEmail(customer).then((data) => {
      //return console.log(data.errors);
      if (data.success) {
        console.log("Return values success " + data);
        setErrors({ identification: "", identification_confirm: "" });
        setData(data.data);
        setOpenConfirmation(true);
      } else {
        console.log("Return values error 1" + data.errors);
        setErrors({
          identification: data.identification,
          identification_confirm: data.identification_confirm,
        });
      }
    });
  };

  const classes = useStyles();
  let lastPath = sessionStorage.getItem("lastPath");

  const { from } = window.location.state || {
    from: {
      pathname: lastPath,
    },
  };
  if (values.redirectToReferrer) {
    return <Redirect to={from} />;
  }

  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Typography
            type="headline"
            variant="h4"
            gutterBottom
            align="center"
            className={classes.title}
          >
            ACsenteret Support Portal{" "}
          </Typography>
          <p>Oppdater eller legg inn din e-post under.</p>
          <p
            style={{
              backgroundColor: "lightgreen",
              padding: "8px",
              borderRadius: "1em",
            }}
          >
            NB: Du må logge deg inn på nytt etter du har lagt til eller
            oppdatert e-post.
          </p>
          <Typography
            type="headline"
            variant="body1"
            gutterBottom
            align="center"
            className={classes.title}
          >
            Nåværende e-post:
          </Typography>
          <InputLabel htmlFor="identification">
            {auth.isAuthenticated().data.email
              ? auth.isAuthenticated().data.email
              : "Ingen e-post registrert"}
          </InputLabel>
          <TextField
            id="email"
            label="E-post"
            className={classes.textField}
            value={values.email}
            onChange={handleChange("email")}
            margin="normal"
            variant="outlined"
            helperText={errors.identification ? errors.identification : ""}
            error={errors.identification ? true : false}
          />
          <TextField
            id="email_confirm"
            label="Bekreft e-post"
            className={classes.textField}
            value={values.email_confirm}
            onChange={handleChange("email_confirm")}
            margin="normal"
            variant="outlined"
            helperText={
              errors.identification_confirm ? errors.identification_confirm : ""
            }
            error={errors.identification_confirm ? true : false}
          />
          <br />
          <br />
          <Button
            size="large"
            color="primary"
            variant="contained"
            onClick={clickSubmit}
            className={classes.submit}
          >
            Oppdater e-post
          </Button>
          <br />
        </CardContent>
        <CardActions>
          <Button
            color="default"
            variant="contained"
            onClick={goFrontpage}
            className={classes.submit}
          >
            Til fremsiden
          </Button>
        </CardActions>
      </Card>
      <Dialog open={openConfirmation} disableBackdropClick maxWidth="xs">
        <DialogTitle align="center">E-post oppdatert</DialogTitle>
        <DialogContent>
          <DialogContentText align="center">
            Din e-post ble oppdatert til {values.email}
            <p>Trykk ok for å logge inn igjen.</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={goHome}
            className={classes.submit}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openErrorNoEmail} disableBackdropClick maxWidth="xs">
        <DialogTitle align="center">E-post oppdatert</DialogTitle>
        <DialogContent>
          <DialogContentText align="center">{errors.msg}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={handleCloseEmailError}
            className={classes.submit}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
