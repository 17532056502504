const options = [
  {
    id: 1,
    value: "vp-sol-sensor",
    label: "Vind og sol sensor",
    links: [
      {
        isChecked: false,
        desc: "Generelt",
        url: "https://docs.google.com/document/d/1NN_KiIU4XRPMZdqO-qcR1nOT-oHFO7Gq6_K8z7fjb9s/edit#heading=h.dja8o6o39ot3",
      },
    ],
  },
  {
    id: 2,
    value: "no-start",
    label: "Produktet vil ikke gå",
    links: [
      {
        isChecked: false,
        desc: "Generelt",
        url: "https://docs.google.com/document/d/1NN_KiIU4XRPMZdqO-qcR1nOT-oHFO7Gq6_K8z7fjb9s/edit#heading=h.arwxoyz9vhau",
      },
    ],
  },
  {
    id: 3,
    value: "kosmetisk",
    label: "Kosmetisk reklamasjon",
    links: [
      {
        isChecked: false,
        desc: "Kosmetisk reklamasjon.",
        url: "https://docs.google.com/document/d/1NN_KiIU4XRPMZdqO-qcR1nOT-oHFO7Gq6_K8z7fjb9s/edit#heading=h.zej5ejmihm95",
      },
    ],
  },
];

module.exports = options;
