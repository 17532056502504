import React from 'react'
import PropTypes from "prop-types";
import { Box, Checkbox, FormControlLabel, Typography, makeStyles, Link } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 'bold',
  },
  link: {
    textDecoration: 'underline',
  },
  text: {
    whiteSpace: 'pre-wrap'
  }
}));

const GDPRCheckBox = (props) => {
  const classes = useStyles();
  const { onChecked, checked } = props;

  return (
    <Box>
      <Typography className={classes.header}>
        Personvern og informasjonssikkerhetsavtale (GDPR)
        <Typography component="span" color="error">
          &nbsp;*
        </Typography>
      </Typography>
      <FormControlLabel
        label={
          <Typography className={classes.text}>
            Jeg godtar lagring og behandling av mine data.{'\n'}For detaljer om hva det innebærer se vår&nbsp;
            <Typography className={classes.link} component={Link} href="https://acsenteret.no/personvern/" target="_blank" rel="noopener noreferrer">personvernsærklæring her</Typography>
            .
          </Typography>}
        control={
          <Checkbox
            color="primary"
            onChange={(e) => onChecked(e.target.checked)}
            checked={checked}
            name="gdpr"
          />
        }
      />
    </Box>
  )
}

GDPRCheckBox.propTypes = {
  checked: PropTypes.bool,
  onChecked: PropTypes.func,
}

export default GDPRCheckBox