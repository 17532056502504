import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import logo from "../../assets/images/acsenteret_logo.png";
import auth from "../auth/auth-helper";
import TroubleshootOptions from "../data/troubleshoot-options-luftvann";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import HomeIcon from "@material-ui/icons/Home";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { Link } from "react-router-dom";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import ForwardIcon from "@material-ui/icons/Forward";
import { green, purple, red } from "@material-ui/core/colors";
import Collapse from "@material-ui/core/Collapse";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  rootList: {
    width: "100%",
    color: "#000000 !important",
  },
  noLinkChild: {
    color: "black !important",
    textDecoration: "none",
    paddingLeft: theme.spacing(0),
  },
  card: {
    maxWidth: "50%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  formControl: {
    margin: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      maxWidth: "100%",
    },
  },
  formControlHeader: {
    margin: theme.spacing(0),
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      fontWeight: "600",
      fontSize: "2rem",
      paddingBottom: "0.5rem",
    },
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
    color: "#000000",
    borderColor: "#FFFFFF",
  },
  buttonHeader: {
    margin: theme.spacing(1, 1, 0, 0),
    color: "#FFF",
    borderColor: "#FFFFFF",
    transition: "background-color 0.5s",
    "&:hover": {
      backgroundColor: "green",
      borderColor: "white",
    },
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  palette: {
    primary: green,
  },
  nested: {
    paddingLeft: theme.spacing(0),
    textDecoration: "underline",
    color: "#0000FF !important",
    "&:hover": {
      color: "#0269A4 !important",
      textDecoration: "none",
    },
  },
  nestedChild: {
    paddingLeft: theme.spacing(1.5),
    color: "#000000 !important",
  },
  nestedParent: {
    paddingLeft: theme.spacing(0),
    color: "#000000 !important",
    fontWeight: "bold",
    fontSize: "1.2em",
  },
  linksHeader: {
    paddingLeft: theme.spacing(0),
    textDecoration: "underline",
    fontSize: "1em",
    color: "#FFFFFF !important",
    "&:hover": {
      color: "#44FF44 !important",
      textDecoration: "none",
    },
  },
}));

function getSteps() {
  return ["Skjema utfylling", "Svar og løsninger", "Oppsummering"];
}

export default function TroubleshootingLV() {
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [data] = useState(TroubleshootOptions);
  const [checked, setChecked] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [isChecked, setIsChecked] = useState(data.slice().fill(false));
  const [isIssuesChecked, setIsIssuesChecked] = useState([]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleChange = (event) => {
    const { name } = event.target;
    setIsIssuesChecked(
      isIssuesChecked.includes(name)
        ? isIssuesChecked.filter((c) => c !== name)
        : [...isIssuesChecked, name]
    );
  };

  const handleCheck = (event) => {
    const { value, index } = event.target;
    setChecked(
      checked.includes(value)
        ? checked.filter((c) => c !== value)
        : [...checked, value]
    );
    findTroubleLinks(event.target.value);
  };

  const toggleCheckboxValue = (index) => {
    setIsChecked(isChecked.map((v, i) => (i === index ? !v : v)));
  };

  const findTroubleLinks = (data) => {
    for (var i = 0; i < TroubleshootOptions.length; i++) {
      if (TroubleshootOptions[i].value === data) {
        setSelectedData(
          selectedData.includes(TroubleshootOptions[i])
            ? selectedData.filter((c) => c !== TroubleshootOptions[i])
            : [...selectedData, TroubleshootOptions[i]]
        );
        break;
      }
    }
  };

  const classes = useStyles();

  const history = useHistory();
  const goHome = () => {
    history.push("/");
  };

  const CheckBoxes = data.map((data, index) => {
    return (
      <FormControlLabel
        key={data.id}
        name={data.label}
        value={data.value}
        label={data.label}
        control={
          <Checkbox
            checked={isChecked[index]}
            onClick={() => toggleCheckboxValue(index)}
          />
        }
      />
    );
  });

  const SelectedCheckBoxes = selectedData.map((data) => {
    return (
      <Collapse in={true} timeout="auto" unmountOnExit>
        <List className={classes.rootList}>
          <ListItem key={data.id}>
            <ListItemText
              key={data.id}
              disableTypography
              className={classes.nestedParent}
              primary={data.label + ":"}
            />
          </ListItem>
        </List>

        {data.links.map((value) => {
          return (
            <List component="div" disablePadding>
              <ListItem key={value.id}>
                <Checkbox
                  key={value.id}
                  name={value.desc}
                  className={classes.nestedChild}
                  onChange={handleChange}
                />

                {value.url ? (
                  <a
                    className={classes.nested}
                    style={{ display: "inline-flex" }}
                    className="external"
                    target="_blank"
                    href={value.url}
                  >
                    <ListItemText
                      className={classes.nested}
                      primary={value.desc}
                    />
                  </a>
                ) : (
                  <ListItemText
                    className={classes.noLinkChild}
                    primary={value.desc}
                  />
                )}
              </ListItem>
            </List>
          );
        })}
      </Collapse>
    );
  });

  const GreenButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(purple[500]),
      backgroundColor: green[500],
      "&:hover": {
        backgroundColor: green[700],
      },
      margin: theme.spacing(1, 1, 0, 0),
    },
  }))(Button);

  const PurpleButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(red[500]),
      backgroundColor: red[500],
      "&:hover": {
        backgroundColor: red[700],
      },
      margin: theme.spacing(1, 1, 0, 0),
    },
  }))(Button);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <FormControl component="fieldset" className={classes.formControl}>
            <Typography gutterBottom component={"span"} variant="h3">
              Feilsøkingsveiledning for Luft/vann varmepumpe
            </Typography>
            <Divider />
            <Typography gutterBottom component={"span"} variant="h6">
              Dette skjemaet skal hjelpe deg med å feilsøke varmepumpen din,
              velg én eller flere relevante alternativ og trykk så "Neste" for
              videre veiledning.
            </Typography>
            <FormGroup
              aria-label="troubleshooting"
              name="troubleshooting"
              value={checked}
              onChange={handleCheck}
            >
              {CheckBoxes}
            </FormGroup>
          </FormControl>
        );
      case 1:
        return (
          <FormControl component="fieldset" className={classes.formControl}>
            <Typography gutterBottom component={"span"} variant="h3">
              Feilsøking
            </Typography>
            <Divider />
            <Typography gutterBottom component={"span"} variant="h6">
              Her er en sjekkliste for feilsøking av varmepumpen din - vennligst
              kryss av når du har fullført hver enkelt punkt, når du er ferdig,
              klikk videre for å fullføre skjemaet. Hver enkelt punkt har en
              link som vil åpne ett dokument i en ny fane, følg instruksjoner i
              dokumentet.
            </Typography>
            {SelectedCheckBoxes}
          </FormControl>
        );
      case 2:
        return (
          <FormControl component="fieldset" className={classes.formControl}>
            <Typography gutterBottom component={"span"} variant="h3">
              Oppsummering
            </Typography>
            <Divider />
            <Typography gutterBottom component={"span"} variant="h6">
              Hvis du ikke klarte å fikse problemet ditt via veiledningen så kan
              du bestille reparasjon \ service på varmepumpen din, klikk på
              "Ikke løst" for å gå til neste skjema og les mer om videre skritt
              for å få hjelp med å løse problemet ditt.
            </Typography>

            {selectedData.map((value, index) => {
              return (
                <>
                  <List>
                    <ListItemIcon>
                      <CheckCircleOutlineIcon style={{ color: "green" }} />
                      <ListItemText
                        style={{ color: "green", paddingLeft: "5px" }}
                        secondary={value.label}
                      />
                    </ListItemIcon>
                  </List>
                </>
              );
            })}
          </FormControl>
        );
      default:
        return "Ukjent steg";
    }
  }

  return (
    <div style={{ width: "100%" }}>
      <header className="App-header">
        <img src={logo} alt="ACsenteret" className={classes.logo} />
        <Box variant="h4" component="h2" m={3} mx="auto">
          Velkommen til vår support portal
        </Box>
        {auth.isAuthenticated() ? (
          <Box style={{ color: "lightgray", fontSize: "0.5em" }}>
            Hei, du er innlogget som {auth.isAuthenticated().data.name}{" "}
            {auth.isAuthenticated().data.email ? (
              <>
                ({auth.isAuthenticated().data.email}){" "}
                <Link className={classes.linksHeader} to="/oppdater-epost">
                  Klikk her for å endre e-post
                </Link>
              </>
            ) : (
              <>
                (Ingen e-post registrert){" "}
                <Link className={classes.linksHeader} to="/oppdater-epost">
                  Klikk her for legge til e-post
                </Link>
              </>
            )}
          </Box>
        ) : (
          <Box>Ikke innlogget</Box>
        )}
        <Box m={0.6} mx="auto">
          <Button
            variant="outlined"
            color="primary"
            justifycontent="center"
            onClick={goHome}
            className={classes.buttonHeader}
            startIcon={<HomeIcon />}
          >
            Til fremsiden
          </Button>
        </Box>
      </header>
      <Box
        display="flex"
        justifycontent="center"
        m={1}
        p={1}
        bgcolor="background.paper"
        className={classes.card}
      >
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <Typography component={"span"}>
                  {getStepContent(index)}
                </Typography>
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                      style={{
                        display: activeStep === 2 ? "none" : "inline-flex",
                      }}
                      variant="outlined"
                      color="primary"
                    >
                      Tilbake
                    </Button>
                    <Button
                      disabled={selectedData.length === 0}
                      variant="contained"
                      color="default"
                      style={{ color: "#000000" }}
                      onClick={
                        activeStep === steps.length - 1
                          ? handleBack
                          : handleNext
                      }
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Tilbake" : "Neste"}
                    </Button>
                    {activeStep === steps.length - 1 && (
                      <>
                        <GreenButton
                          variant="contained"
                          color="primary"
                          disabled={activeStep === 0}
                          onClick={goHome}
                          endIcon={<ThumbUpIcon />}
                        >
                          Problem løst
                        </GreenButton>
                        <Link
                          style={{ fontSize: "1em" }}
                          to={{
                            pathname: "/bestill-reparasjon",
                            state: selectedData,
                            previouspath: "Luft/Vann",
                          }}
                        >
                          <PurpleButton
                            variant="contained"
                            color="primary"
                            disabled={activeStep === 0}
                            endIcon={<ForwardIcon />}
                          >
                            Ikke løst
                          </PurpleButton>
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <Typography component={"span"}>
              Fikk du løst ditt problem ?
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </Paper>
        )}
      </Box>
    </div>
  );
}
