import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import HomeIcon from "@material-ui/icons/Home";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import logo from "../../assets/images/acsenteret_logo.png";
import { LinearProgress } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import { green, purple } from "@material-ui/core/colors";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { createTicket } from "../utils/api-user";
import { useHistory } from "react-router-dom";
import UploadService from "../../services/upload-files";
import AttachmentIcon from "@material-ui/icons/Attachment";
import auth from "../auth/auth-helper";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";

import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function getSteps() {
  return ["Velg tjeneste", "Bestill"];
}

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: "50%",
    margin: "0 auto",
    textAlign: "center",
    marginTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  buttonHeader: {
    margin: theme.spacing(1, 1, 0, 0),
    color: "#FFF",
    borderColor: "#FFFFFF",
    transition: "background-color 0.5s",
    "&:hover": {
      backgroundColor: "green",
      borderColor: "white",
    },
  },
  error: {
    verticalAlign: "middle",
  },
  title: {
    marginTop: theme.spacing(2),
    color: theme.palette.openTitle,
  },
  textField: {
    width: "100%",
  },

  submit: {
    margin: "auto",
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  formControlLabel: {
    fontWeight: "600",
  },
  links: {
    fontSize: "1em",
    color: "blue !important",
    textDecoration: "underline",
    "&:hover": {
      color: "purple !important",
    },
  },
  linksHeader: {
    paddingLeft: theme.spacing(0),
    textDecoration: "underline",
    fontSize: "1em",
    color: "#FFFFFF !important",
    "&:hover": {
      color: "#44FF44 !important",
      textDecoration: "none",
    },
  },
  nested: {
    paddingLeft: theme.spacing(0),
    textDecoration: "underline",
    color: "#0000FF !important",
    "&:hover": {
      color: "purple !important",
      textDecoration: "none",
    },
    "&:after": {
      content:
        "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==)",
      margin: "7px 3px 0px 5px",
    },
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 30,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

export default function OrderServiceMisc({ match }) {
  const { state } = useLocation();

  const initialState = {
    description: "",
    files: [],
  };
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({ errors: "" });
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openErrorNoEmail, setOpenErrorNoEmail] = useState(false);
  const [isError, setIsError] = useState("");
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [checked, setChecked] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [conditions, setConditions] = useState(false);
  const [fileInfos, setFileInfos] = useState([]);
  const [loginOpen, setLoginOpen] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    let descriptionField = document.getElementById("description");
    let lastPath = history.location.pathname;

    sessionStorage.setItem("lastPath", lastPath);
    // See if we have an autosave value
    // (this will only happen if the page is accidentally refreshed)
    if (sessionStorage.getItem("descriptionServiceMisc")) {
      // Restore the contents of the text field
      setValues({
        description: sessionStorage.getItem("descriptionServiceMisc"),
      });
    }
    if (sessionStorage.getItem("filesServiceMisc")) {
      setFileInfos(JSON.parse(sessionStorage.getItem("filesServiceMisc")));
    }
    if (sessionStorage.getItem("checkedServices")) {
      setChecked(JSON.parse(sessionStorage.getItem("checkedServices")));
    }
    descriptionField.addEventListener("change", function () {
      // And save the results into the session storage object
      sessionStorage.setItem("descriptionServiceMisc", descriptionField.value);
      //sessionStorage.setItem("files", fileInfos);
    });
  }, []);

  useEffect(() => {
    sessionStorage.setItem("filesServiceMisc", JSON.stringify(fileInfos));
    return () => {
      console.log("Cleanup");
    };
  }, [fileInfos]);

  const handleNext = () => {
    if (activeStep == 0) {
      if (auth.isAuthenticated()) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setLoginOpen(true);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  const handleReset = () => {
    setActiveStep(0);
  };

  const selectFile = (event) => {
    upload(event);
  };

  const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(purple[500]),
      backgroundColor: green[500],
      "&:hover": {
        backgroundColor: green[700],
      },
      margin: theme.spacing(1, 1, 0, 0),
    },
  }))(Button);

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setOpenError(false);
    goHome();
  };

  const handleCloseNoEmail = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setOpenErrorNoEmail(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setOpenError(false);
  };

  const upload = async (event) => {
    setAttachment((data) => [...data, event.target.files]);
    setSelectedFiles(event.target.files);
    let currentFile = event.target.files[0];
    setProgress(0);
    setCurrentFile(currentFile);

    await UploadService.upload(currentFile, (event) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    })
      .then((response) => {
        setFileInfos([...fileInfos, currentFile.name]);
        setMessage(response.data.message);
        setProgress(0);
        setCurrentFile(undefined);
      })
      .catch((err) => {
        setCurrentFile(undefined);
        console.log(err.message);
        setMessage(
          "Klarte ikke laste opp filen (Feil format eller for stor fil)"
        );
        setProgress(0);
      });
    setSelectedFiles(undefined);
  };

  const history = useHistory();

  const goHome = () => {
    history.push("/");
  };

  const goExistingCustomer = () => {
    history.push("/existing");
  };

  const goNewCustomer = () => {
    history.push("/signup");
  };

  const GreenButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(purple[500]),
      backgroundColor: green[500],
      "&:hover": {
        backgroundColor: green[700],
      },
      margin: theme.spacing(1, 1, 0, 0),
    },
  }))(Button);

  const PurpleButton = withStyles((theme) => ({
    root: {
      margin: "0.5em",
      color: theme.palette.getContrastText(purple[500]),
      backgroundColor: purple[500],
      "&:hover": {
        backgroundColor: purple[700],
      },
    },
  }))(Button);

  const handleChange = (name) => (event) => {
    setValues({
      ...values,
      [name]: event.target.value,
    });
  };

  const handleCheckCondition = (event) => {
    setConditions(event.target.checked);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const submitWithEmail = async (event) => {
    console.log("Sumbitted with email" + event.target.value);
    setOpenErrorNoEmail(false);
    clickSubmit();
  };

  const handleChangeService = (event) => {
    const { name } = event.target;
    setChecked(
      checked.includes(name)
        ? checked.filter((c) => c !== name)
        : [...checked, name]
    );
    sessionStorage.setItem(
      "checkedServices",
      JSON.stringify(
        checked.includes(name, event.target.checked)
          ? checked.filter((c) => c !== name)
          : [...checked, name]
      )
    );
    //setChecked({ ...checked, [name]: event.target.checked });
  };

  const clickSubmit = () => {
    console.log(attachment);
    setIsLoading(true);
    const haveEmail = true;
    const ticket_data = {
      description:
        values.description +
          "<br><br><strong>Fra valg av tjeneste:<br></strong>" +
          checked.map((services) => "<li>" + services + "</li>").join("") ||
        undefined,
      files: fileInfos || undefined,
      service: checked || undefined,
      email: auth.isAuthenticated().data.email || email,
      name: auth.isAuthenticated().data.name,
      phone: auth.isAuthenticated().data.phonenumber,
      address: auth.isAuthenticated().data.address,
      zipcode: auth.isAuthenticated().data.zip,
      city: auth.isAuthenticated().data.city,
      custid: auth.isAuthenticated().data.custid,
      otp_id: auth.isAuthenticated().data.otp_id,
      category: "Bestilling av tjeneste",
    };
    if (!ticket_data.email) {
      setIsLoading(false);
      setOpenErrorNoEmail(true);
    }
    if (haveEmail) {
      createTicket(ticket_data).then((data) => {
        console.log(data);
        if (data.success) {
          setOpen(true);
          setIsLoading(false);
        } else {
          setErrors({ error: data.error });
          setIsLoading(false);
        }
      });
    }
  };

  const UploadFiles = () => {
    return (
      <div className="mg20">
        {currentFile && (
          <Box
            m={3}
            mx="auto"
            display="flex"
            justifycontent="flex-start"
            alignItems="center"
          >
            <Box m={3} mx="auto" width="50%">
              <Typography variant="body2" color="textSecondary">
                {`${progress}%`}
              </Typography>
              <BorderLinearProgress variant="determinate" value={progress} />
            </Box>
          </Box>
        )}

        <Box m={3} mx="auto" width="100%">
          <Typography
            gutterBottom
            align="left"
            style={{
              backgroundColor: "lightgreen",
              borderRadius: "12px",
              margin: "2em",
              padding: "1em",
            }}
          >
            For at vi skal kunne hjelpe deg på en mest mulig presis og effektiv
            måte så ber vi deg legge ved et bilde av{" "}
            <strong>merkeskiltet</strong> på ute eller innedelen av varmepumpen
            din. Se eksempel .
          </Typography>
          {selectedFiles ? (
            <ul>
              <li>
                {selectedFiles && selectedFiles.length > 0
                  ? selectedFiles[0].name
                  : null}
              </li>
            </ul>
          ) : null}
        </Box>
        <label htmlFor="btn-upload" style={{ margin: "1em" }}>
          <input
            id="btn-upload"
            name="btn-upload"
            accept="image/png, image/jpeg, image/jpeg, image/tif, application/pdf, video/mp4, video/ogg, video/avi, video/mov, video/webm, video/avchd, video/wmv, video/x-flv"
            style={{ display: "none" }}
            type="file"
            onChange={selectFile}
          />
          <Button
            variant="contained"
            color="secondary"
            size="large"
            component="span"
            onChange={selectFile}
            endIcon={<AttachmentIcon />}
          >
            Last opp vedlegg
          </Button>
        </label>
        <Box m={3} mx="auto">
          <Typography className={`upload-message ${isError ? "error" : ""}`}>
            <strong>{message}</strong>
          </Typography>
        </Box>
        <Box m={3} mx="auto">
          {fileInfos.length > 0 && (
            <Typography variant="h6">Vedlegg:</Typography>
          )}
          <ul className="list-group">
            {fileInfos &&
              fileInfos.map((file, index) => (
                <ListItem divider key={file}>
                  {file}
                </ListItem>
              ))}
          </ul>
        </Box>
      </div>
    );
  };

  const classes = useStyles();

  function getStepContent(step) {
    const {
      Montering,
      Demontering,
      Flytting,
      Reparasjon,
      Diagnose,
      Brukerhjelp,
    } = checked;

    switch (step) {
      case 0:
        return (
          <>
            <CardContent>
              <Typography gutterBottom variant="h3">
                Bestill tjeneste
              </Typography>
              <Divider />
              <Typography gutterBottom variant="h6" align="left">
                Velg hvilken type tjeneste du har behov for, gi en liten
                beskrivelse av hva du trenger hjelp med og legg ved bilder av
                varmepumpen.
              </Typography>
              <Typography
                align="left"
                variant="h6"
                style={{
                  fontWeight: 600,
                  marginTop: "1em",
                  marginBottom: "0.5em",
                }}
              >
                Velg tjeneste du har behov for:
              </Typography>
              <FormGroup>
                <Grid container justify="flex-start">
                  <Grid item sm={4} align="left">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked.includes("Montering")}
                          color="primary"
                          name="Montering"
                          id="montering"
                          onChange={handleChangeService}
                        />
                      }
                      label="Montering av varmepumpe"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked.includes("Demontering")}
                          color="primary"
                          name="Demontering"
                          id="demontering"
                          onChange={handleChangeService}
                        />
                      }
                      label="Demontering av varmepumpe"
                    />
                  </Grid>
                  <Grid item sm={4} align="left">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked.includes("Flytting")}
                          color="primary"
                          name="Flytting"
                          id="flytting"
                          onChange={handleChangeService}
                        />
                      }
                      label="Flytting av varmepumpe"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked.includes("ReparasjonLL")}
                          color="primary"
                          name="ReparasjonLL"
                          id="reparasjonLL"
                          onChange={handleChangeService}
                        />
                      }
                      label="Reparasjon av varmepumpe (Luft/luft)"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked.includes("ReparasjonLV")}
                          color="primary"
                          name="ReparasjonLV"
                          id="reparasjonLV"
                          onChange={handleChangeService}
                        />
                      }
                      label="Reparasjon av varmepumpe (Luft/vann)"
                    />
                  </Grid>
                  <Grid item sm={4} align="left">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked.includes("Diagnose")}
                          color="primary"
                          name="Diagnose"
                          id="diagnose"
                          onChange={handleChangeService}
                        />
                      }
                      label="Diagnose av varmepumpe"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked.includes("Brukerhjelp")}
                          color="primary"
                          name="Brukerhjelp"
                          id="brukerhjelp"
                          onChange={handleChangeService}
                        />
                      }
                      label="Brukerhjelp av varmepumpe"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked.includes("Solskjerming")}
                          color="primary"
                          onChange={handleChangeService}
                          name="Solskjerming"
                          id="solskjerming"
                          onChange={handleChangeService}
                        />
                      }
                      label="Reparasjon solskjerming"
                    />
                  </Grid>
                </Grid>
              </FormGroup>
              <Box pt={4}>
                <Typography align="left">
                  <strong>NB!</strong> om du mener dette kan være en reklamasjon
                  så må vi be deg gå gjennom prosessen for reklamasjon og
                  feilsøking. Alle tjenester som bestilles gjennom "Bestill
                  tjenester" vil bli fakturert. Reklamasjons henvendelser gjøres
                  her{" "}
                  <Link to={"/feilsøking-ll"} className={classes.links}>
                    "Reklamasjon og feilsøking - Luft/Luft"
                  </Link>
                  {" , "}
                  <Link to={"/feilsøking-lv"} className={classes.links}>
                    "Reklamasjon og feilsøking - Luft/Vann"
                  </Link>
                  {" , "}
                  <Link to={"/feilsøking-sol"} className={classes.links}>
                    "Reklamasjon og feilsøking - Solskjerming"
                  </Link>
                  {""}
                </Typography>
              </Box>
              <Typography
                align="left"
                variant="h6"
                style={{
                  fontWeight: 600,
                  marginTop: "1em",
                  marginBottom: "0.5em",
                }}
              >
                Gi oss litt mer detaljer om ditt behov:
              </Typography>
              <TextField
                id="description"
                type="text"
                label="Beskrivelse (Påkrevd)"
                className={classes.textField}
                value={values.description}
                onChange={handleChange("description")}
                margin="normal"
                variant="outlined"
                multiline
                rows={15}
                rowsMax={15}
              />
            </CardContent>
            <UploadFiles />
          </>
        );
      case 1:
        return (
          <>
            <CardContent>
              <FormControl component="fieldset" className={classes.formControl}>
                <Typography gutterBottom component={"span"} variant="h3">
                  Oppsummering
                </Typography>
                <Divider />
                <Typography
                  gutterBottom
                  component={"span"}
                  variant="h6"
                  align="left"
                >
                  Her er en oppsummering av hva du sender inn til oss av info,
                  sjekk over at det stemmer og trykk "Bestill", en ticket vil
                  bli opprettet i vårt system og du vil få en bekreftelse sendt
                  på epost.
                </Typography>
                <br />
                <Box
                  align="left"
                  style={{
                    backgroundColor: "whitesmoke",
                    padding: "10px",
                    margin: "10px",
                    borderRadius: "1em",
                  }}
                >
                  <Typography
                    gutterBottom
                    component={"span"}
                    variant="h6"
                    align="left"
                  >
                    Valgte tjenester:
                  </Typography>

                  {checked.map((value, index) => {
                    return (
                      <>
                        <List align="left">
                          <ListItemIcon>
                            <CheckCircleOutlineIcon
                              style={{ color: "green" }}
                            />
                            <ListItemText
                              style={{ color: "green", paddingLeft: "5px" }}
                              secondary={value}
                            />
                          </ListItemIcon>
                        </List>
                      </>
                    );
                  })}
                  <br />
                  <Typography
                    gutterBottom
                    component={"span"}
                    variant="h6"
                    align="left"
                  >
                    Beskrivelse:
                  </Typography>
                  <List align="left">
                    <ListItemIcon>
                      <CheckCircleOutlineIcon style={{ color: "green" }} />
                      <ListItemText
                        style={{ color: "green", paddingLeft: "5px" }}
                        secondary={values.description}
                      />
                    </ListItemIcon>
                  </List>
                  <br />

                  {fileInfos.length > 0 && (
                    <Typography
                      gutterBottom
                      component={"span"}
                      variant="h6"
                      align="left"
                    >
                      Vedlegg:
                    </Typography>
                  )}
                  {fileInfos &&
                    fileInfos.map((value, index) => {
                      console.log(value);
                      return (
                        <>
                          <List align="left">
                            <ListItemIcon>
                              <CheckCircleOutlineIcon
                                style={{ color: "green" }}
                              />
                              <ListItemText
                                style={{ color: "black", paddingLeft: "10px" }}
                                secondary={value}
                              />
                            </ListItemIcon>
                          </List>
                        </>
                      );
                    })}
                </Box>
                <Box m={3} mx="auto" width="100%">
                  <FormControlLabel
                    label={
                      <Typography className={classes.formControlLabel}>
                        <a
                          className={classes.nested}
                          target="_blank"
                          style={{ fontSize: "1em" }}
                          href="https://kontor.acsenteret.no:442/ProduktArk/Prisliste/Informasjon%20standard%20kriterier%20og%20vilk%c3%a5r%20for%20montering.pdf"
                        >
                          Jeg har lest og forstått priser og vilkår for
                          bestilling av varer og tjenester
                        </a>
                      </Typography>
                    }
                    control={
                      <Checkbox
                        checked={conditions}
                        onChange={handleCheckCondition}
                        name="conditions"
                        color="primary"
                      />
                    }
                  />
                </Box>
              </FormControl>
            </CardContent>
          </>
        );
      default:
        return "Ukjent steg";
    }
  }

  return (
    <div style={{ width: "100%" }}>
      <header className="App-header">
        <img src={logo} alt="ACSenteret" className={classes.logo} />
        <Box variant="h4" component="h2" m={3} mx="auto">
          Velkommen til vår support portal
        </Box>
        {auth.isAuthenticated() ? (
          <Box style={{ color: "lightgray", fontSize: "0.5em" }}>
            Hei, du er innlogget som {auth.isAuthenticated().data.name}{" "}
            {auth.isAuthenticated().data.email ? (
              <>
                ({auth.isAuthenticated().data.email}){" "}
                <Link className={classes.linksHeader} to="/oppdater-epost">
                  Klikk her for å endre e-post
                </Link>
              </>
            ) : (
              <>
                (Ingen e-post registrert){" "}
                <Link className={classes.linksHeader} to="/oppdater-epost">
                  Klikk her for legge til e-post
                </Link>
              </>
            )}
          </Box>
        ) : (
          <Box>Ikke innlogget</Box>
        )}
        <Box m={0.6} mx="auto">
          <Button
            variant="outlined"
            color="primary"
            justifycontent="center"
            onClick={goHome}
            className={classes.buttonHeader}
            startIcon={<HomeIcon />}
          >
            Til fremsiden
          </Button>
        </Box>
      </header>

      <Stepper
        className={classes.card}
        activeStep={activeStep}
        orientation="vertical"
      >
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel style={{ textAlign: "justify", fontSize: "1em" }}>
              {label}
            </StepLabel>
            <StepContent>
              <Typography component={"span"}>
                {getStepContent(index)}
              </Typography>
              <div className={classes.actionsContainer}>
                {isLoading && <CircularProgress color="secondary" />}
                <div>
                  <Button
                    style={{ margin: "1em" }}
                    size="large"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                    variant="outlined"
                    color="primary"
                  >
                    Tilbake
                  </Button>
                  <Button
                    size="large"
                    disabled={
                      activeStep === steps.length - 1
                        ? !conditions
                        : isLoading || !values.description || !checked
                    }
                    variant={
                      activeStep === steps.length - 1 ? "contained" : "outlined"
                    }
                    color={
                      activeStep === steps.length - 1 ? "primary" : "secondary"
                    }
                    onClick={
                      activeStep === steps.length - 1 ? clickSubmit : handleNext
                    }
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? "Bestill" : "Neste"}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <Dialog open={open} disableBackdropClick maxWidth="xs">
        <DialogTitle align="center">Sak ble opprettet</DialogTitle>
        <DialogContent>
          <DialogContentText align="center">
            Sak ble opprettet, vi har sendt deg en epost med mer info. <br />
            Her du kan oppdatere sak, se status eller sende oss en melding.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={goHome}
            className={classes.submit}
          >
            Ok
          </Button>
          <Button
            color="secondary"
            variant="contained"
            //onClick={() => clickSubmit()}
            onClick={handleCloseSuccess}
            className={classes.submit}
          >
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={loginOpen} disableBackdropClick maxWidth="xs">
        <DialogTitle align="center">Logg inn</DialogTitle>
        <DialogContent>
          <DialogContentText align="center">
            Du må logge inn for å fullføre bestillingen. <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={goExistingCustomer}
            className={classes.submit}
          >
            Eksisterende kunde
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={goNewCustomer}
            className={classes.submit}
          >
            Ny kunde
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Det oppstod en feil under opprettelse av sak, prøv igjen eller kontakt
          oss direkte.
        </Alert>
      </Snackbar>
      <Dialog
        open={openErrorNoEmail}
        onClose={handleCloseNoEmail}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Mangler e-post</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Du må ha en e-post registrert for å fullføre skjemaet, legg inn din
            e-post og prøv igjen!
          </DialogContentText>

          <TextField
            autoFocus
            margin="dense"
            id="email"
            name="email"
            onChange={handleEmailChange}
            label="E-post Adresse"
            type="email"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleCloseNoEmail}
            color="primary"
          >
            Avbryt
          </Button>
          <Button
            variant="contained"
            onClick={submitWithEmail}
            color="secondary"
          >
            Fullfør innsendelse
          </Button>
        </DialogActions>
      </Dialog>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>Fikk du løst ditt problem ?</Typography>
          <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button>
        </Paper>
      )}
    </div>
  );
}
