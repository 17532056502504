import React, { Component } from "react";
import "./App.css";
import "./index.css";

import Landing from "./components/layout/landing_no_auth";
import Existing from "./components/layout/existing_customer";
import MagicLinkLogin from "./components/layout/MagicLinkLogin";
import Signup from "./components/layout/new_user";
import TroubleshootLL from "./components/layout/TroubleshootLL";
import TroubleshootLV from "./components/layout/TroubleshootLV";
import TroubleshootSOL from "./components/layout/TroubleshootSOL";
import OrderRepair from "./components/layout/OrderRepair";
import OrderParts from "./components/layout/OrderParts";
import OrderService from "./components/layout/OrderService";
import OrderServiceMisc from "./components/layout/OrderServiceMisc";
import CookieConsent from "./components/layout/cookie_consent";
import UpdateCustomerEmail from "./components/layout/UpdateCustomerEmail";
import PrivateRoute from "../src/components/auth/PrivateRoute";
import { HashRouter as Router, Route } from "react-router-dom";
import Feedback from "./components/layout/Feedback";

function App() {
  return (
    <div>
      <Router>
        <Route path="/landing" component={Landing} />
        <Route exact path="/" component={Landing} />
        <Route path="/existing" component={Existing} />
        <Route path="/logg-inn-m/:token" component={MagicLinkLogin} />
        <Route path="/signup" component={Signup} />
        <PrivateRoute path="/feilsøking-ll" component={TroubleshootLL} />
        <PrivateRoute path="/feilsøking-lv" component={TroubleshootLV} />
        <PrivateRoute path="/feilsøking-sol" component={TroubleshootSOL} />
        <PrivateRoute path="/bestill-reparasjon" component={OrderRepair} />
        <Route path="/bestill-deler" component={OrderParts} />
        <Route path="/bestill-service" component={OrderService} />
        <Route path="/bestill-tjeneste" component={OrderServiceMisc} />
        <Route path="/informasjonskapsel" component={CookieConsent} />
        <Route path="/tilbakemelding" component={Feedback} />
        <PrivateRoute path="/oppdater-epost" component={UpdateCustomerEmail} />
      </Router>
    </div>
  );
}

export default App;
