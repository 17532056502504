import "../../App.css";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { HashRouter, Route, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import FormRow from "../cards";
import logo from "../../assets/images/acsenteret_logo.png";
import auth from "../auth/auth-helper";
import { Link } from "react-router-dom";
import GDPRDialog from "../gdpr/GDPRDialog";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1), //grid padding
    margin: "0 auto",
    padding: "1rem",
    textAlign: "center",
    height: "250px",
    width: "250px",
    color: theme.palette.text.secondary,
  },
  knowledgeList: {
    borderRadius: "5px",
    width: "100%",
    margin: "0 auto",
    overflow: "hidden",
    marginTop: "30px",
    marginBottom: "30px",
  },
  links: {
    paddingLeft: theme.spacing(0),
    textDecoration: "underline",
    fontSize: "1em",
    color: "#FFFFFF !important",
    "&:hover": {
      color: "#44FF44 !important",
      textDecoration: "none",
    },
  },
}));

function Landing() {
  const classes = useStyles();
  const [question, setQuestions] = useState("");
  const history = useHistory();

  useEffect(() => {
    let lastPath = history.location.pathname;

    sessionStorage.setItem("lastPath", lastPath);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="ACSenteret" className={classes.logo} />
        <Box variant="h4" component="h2" m={3} mx="auto">
          Velkommen til vår support portal
        </Box>
        {auth.isAuthenticated() ? (
          <Box style={{ color: "lightgray", fontSize: "0.5em" }}>
            Hei, du er innlogget som {auth.isAuthenticated().data.name}{" "}
            {auth.isAuthenticated().data.email ? (
              <>
                ({auth.isAuthenticated().data.email}){" "}
                <Link className={classes.links} to="/oppdater-epost">
                  Klikk her for å endre e-post
                </Link>
              </>
            ) : (
              <>
                (Ingen e-post registrert){" "}
                <Link className={classes.links} to="/oppdater-epost">
                  Klikk her for legge til e-post
                </Link>
              </>
            )}
          </Box>
        ) : (
          <Box>Ikke innlogget</Box>
        )}
      </header>

      <section className={classes.knowledgeList}>
        <Grid container spacing={1}>
          <Grid container item xs={12} spacing={1}>
            <FormRow />
          </Grid>
        </Grid>
      </section>
      <GDPRDialog />
    </div>
  );
}

export default Landing;
