import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "./auth-helper";

const PrivateRoute = ({ component: Component, ...rest }) => {
  // Add your own authentication on the below line.
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/existing", state: { from: props.location } }}
          />
        )
      }
    />
  );
};
export default PrivateRoute;
