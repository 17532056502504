import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import { Link } from "react-router-dom";
import { registerUser } from "../utils/api-user";
import { useHistory } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import GDPRCheckBox from "../gdpr/GDPRCheckBox";
import { Tooltip } from "@material-ui/core";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 600,
    margin: "auto",
    textAlign: "center",
    marginTop: theme.spacing(7),
    paddingBottom: theme.spacing(3),
  },
  error: {
    verticalAlign: "middle",
  },
  title: {
    marginTop: theme.spacing(2),
    color: theme.palette.openTitle,
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 300,
  },
  submit: {
    margin: "auto",
    marginBottom: theme.spacing(2),
  },
}));

export default function Signup() {
  const initialState = {
    first_name: "",
    last_name: "",
    postal_code: null,
    phone: null,
    street_address: "",
    city: "",
    email: "",
  };

  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({ errors: "" });
  const [openError, setOpenError] = useState(false);
  const [open, setOpen] = useState(false);

  // GDPR
  const [gdprChecked, setGDPRChecked] = useState(false);

  const history = useHistory();
  const goHome = () => {
    history.push("/");
  };

  const goSignIn = () => {
    history.push("/existing");
  };

  const handleChange = (name) => (event) => {
    setValues({
      ...values,
      [name]: event.target.value,
    });
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setOpenError(false);

    goSignIn();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setOpenError(false);
  };

  const clickSubmit = () => {

    const user = {
      first_name: values.first_name || undefined,
      last_name: values.last_name || undefined,
      email: values.email || undefined,
      street_address: values.street_address || undefined,
      city: values.city || undefined,
      phone: values.phone || undefined,
      postal_code: values.postal_code || undefined,
    };
    registerUser(user).then((data) => {
      console.log("Response data " + data.success);
      if (data.success) {
        setErrors({ errors: "" });
        setOpen(true);
      } else {
        //setOpenError(true);
        setErrors({ errors: data });
      }
    });
  };

  const classes = useStyles();

  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Typography
            type="headline"
            variant="h4"
            gutterBottom
            align="center"
            alignItems="center"
            className={classes.title}
          >
            ACsenteret Support Portal
          </Typography>
          <p>Registrer nytt kundeforhold for support portalen</p>
          <form autocomplete="on">
            <TextField
              autocomplete="given-name"
              id="first_name"
              name="first_name"
              type="text"
              label="Fornavn (*)"
              className={classes.textField}
              value={values.first_name}
              onChange={handleChange("first_name")}
              margin="normal"
              variant="outlined"
              helperText={errors.errors ? errors.errors.first_name : ""}
              error={errors.errors.first_name}
            />
            <br />
            <TextField
              id="last_name"
              name="last_name"
              autocomplete="family-name"
              type="text"
              label="Etternavn (*)"
              className={classes.textField}
              value={values.last_name}
              onChange={handleChange("last_name")}
              margin="normal"
              variant="outlined"
              helperText={errors.errors ? errors.errors.last_name : ""}
              error={errors.errors.last_name}
            />
            <br />
            <TextField
              id="street_address"
              name="street_address"
              autocomplete="street-address"
              type="text"
              label="Gateadresse (*)"
              className={classes.textField}
              value={values.street_address}
              onChange={handleChange("street_address")}
              margin="normal"
              variant="outlined"
              helperText={errors.errors ? errors.errors.street_address : ""}
              error={errors.errors.street_address}
            />
            <br />
            <TextField
              id="postal_code"
              name="postal_code"
              autocomplete="postal-code"
              type="number"
              label="Postnummer (*)"
              name="postal-code"
              className={classes.textField}
              value={values.postal_code}
              onChange={handleChange("postal_code")}
              margin="normal"
              variant="outlined"
              helperText={errors.errors ? errors.errors.postal_code : ""}
              error={errors.errors.postal_code}
            />
            <br />
            <TextField
              id="city"
              type="text"
              name="city"
              autocomplete="address-level2"
              label="Sted (*)"
              name="city"
              className={classes.textField}
              value={values.city}
              onChange={handleChange("city")}
              margin="normal"
              variant="outlined"
              helperText={errors.errors ? errors.errors.city : ""}
              error={errors.errors.city}
            />
            <br />
            <TextField
              id="phone"
              type="number"
              name="phone"
              autocomplete="tel-national"
              label="Mobilnummer (*)"
              className={classes.textField}
              value={values.phone}
              onChange={handleChange("phone")}
              margin="normal"
              variant="outlined"
              helperText={errors.errors ? errors.errors.phone : ""}
              error={errors.errors.phone}
            />
            <br />
            <TextField
              id="email"
              type="text"
              name="email"
              autocomplete="email"
              label="E-Post (*)"
              className={classes.textField}
              value={values.email}
              onChange={handleChange("email")}
              margin="normal"
              variant="outlined"
              helperText={errors.errors ? errors.errors.email : ""}
              error={errors.errors.email}
            />

            <br />
            <br />
          </form>
          <GDPRCheckBox checked={gdprChecked} onChecked={setGDPRChecked} />
        </CardContent>
        <CardActions>
          <Button
            size="large"
            color="default"
            variant="contained"
            onClick={goHome}
            className={classes.submit}
            startIcon={<ArrowBackIcon />}
          >
            Gå tilbake
          </Button>
          <Tooltip title={!gdprChecked ? 'Du må godta lagring av data før du kan fortsette' : ''} arrow>
            <span>
              <Button
                size="large"
                color="primary"
                variant="contained"
                onClick={clickSubmit}
                className={classes.submit}
                endIcon={<PersonAddIcon />}
                disabled={!gdprChecked}
              >
                Registrer kunde
              </Button>
            </span></Tooltip>
        </CardActions>
      </Card>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleCloseSuccess}
      >
        <Alert onClose={handleCloseSuccess} severity="success">
          Du ble lagt inn med en ny bruker, vennligst oppgi mobilnummer eller
          epost på logg inn siden for å autentisere din nye bruker.
        </Alert>
      </Snackbar>
      <Snackbar open={openError} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Noe gikk galt, det er mulig du allerede finnes i våre systemer
          allerede, <p>prøv å logg inn igjen</p>
        </Alert>
      </Snackbar>
      <Dialog open={open} disableBackdropClick>
        <DialogTitle>Ny kunde</DialogTitle>
        <DialogContent>
          <DialogContentText>Ny kunde lagt til.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Link to="/existing">
            <Button color="primary" variant="contained" autoFocus="autoFocus">
              Logg inn
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
    </div>
  );
}
