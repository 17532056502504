import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import { magicSignin } from "../utils/api-user";
import auth from "../auth/auth-helper";
import { Redirect, useParams } from "react-router-dom";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 600,
    margin: "auto",
    textAlign: "center",
    marginTop: theme.spacing(15),
    paddingBottom: theme.spacing(2),
  },
  error: {
    verticalAlign: "middle",
  },
  title: {
    marginTop: theme.spacing(2),
    color: theme.palette.openTitle,
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 300,
  },
  submit: {
    margin: "auto",
    marginBottom: theme.spacing(2),
  },
}));

export default function MagicLinkLogin() {
  const initialState = {
    identification: "",
  };

  const [values, setValues] = useState(initialState);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState([{ identification: "", otp_code: "" }]);
  const [verifyCode, setVerifyCode] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const { token } = useParams();

  useEffect(async () => {
    console.log("Token: " + token);
    await magicSignin(token).then((response) => {
          console.log("Response: " + response.data.success);

      if (response.data.success) {
        setIsSuccess(true);
        setErrors({ otp_code: "", identification: "" });
        auth.authenticate(response.data, (user) => {
          setValues({ redirectToReferrer: true });
        });
      } else {
        if (response.error) {
          setErrors({ otp_code: response.error, identification: "" });
        } else {
          setErrors({ otp_code: response.otp_code, identification: "" });
        }
      }
    });
  }, [isSuccess]);

  const history = useHistory();
  const goHome = () => {
    history.push("/");
  };

  const handleVerify = () => {};

  const classes = useStyles();
  let lastPath = sessionStorage.getItem("lastPath");

  const { from } = window.location.state || {
    from: {
      pathname: lastPath,
    },
  };
  if (values.redirectToReferrer) {
    return <Redirect to={from} />;
  }

  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Typography
            type="headline"
            variant="h4"
            gutterBottom
            align="center"
            className={classes.title}
          >
            Logger inn...
          </Typography>
          <CircularProgress disableShrink />
        </CardContent>
      </Card>
    </div>
  );
}
